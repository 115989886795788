import Login from "./components/auth/login";
import Register from "./components/auth/register";

import Header from "./components/header";
import Home from "./components/home";
import Checkout from "./components/checkout";
import Profile from "./components/profile";
import LandingPage from "./components/landing";

import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/css/argon-design-system-react.css";
import Footer from "./components/footer";
function App() {
  const routesArray = [
    {
      path: "*",
      element: <LandingPage  />,
    },
    {
      path: "/landing",
      element: <LandingPage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
  //   {
  //     path: "/home",
  //     element: <Home />,
  //   }, 
  //   { path: "/checkout",
  //   element: <Checkout />,
  // },
    {
      path: "/profile",
    element: <Profile />,
    }
  ];
  let routesElement = useRoutes(routesArray);
  return (
    <AuthProvider>
      <Header />
      <div className="w-full flex flex-col">{routesElement}</div>

      {/* <div className="w-full h-screen flex flex-col">{routesElement}</div> */}
      <Footer />
    </AuthProvider>
  );
}

export default App;
