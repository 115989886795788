import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyC63F1rCsxZevMXSbpsdlgOg0WTKKrlcXs",
  authDomain: "wonmove-f6094.firebaseapp.com",
  projectId: "wonmove-f6094",
  storageBucket: "wonmove-f6094.appspot.com",
  messagingSenderId: "872717642881",
  appId: "1:872717642881:web:551277af9657491374f180"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);

export { app, auth, db };
