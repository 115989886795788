import React, { useEffect } from 'react';
import { Link} from 'react-router-dom'
import classnames from "classnames";
import { db } from '../../firebase/firebase'
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { BsCurrencyExchange, BsFillRocketTakeoffFill, BsBank, BsChatFill} from "react-icons/bs";

import {
    Badge,
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    InputGroup,
    // InputGroupAddon,
    InputGroupText,
    CardImg,
    Form,
    Label,
    } from "reactstrap";
  import { useState, UseEffect } from 'react'

const LandingPage = () =>{
  const nf = new Intl.NumberFormat()
  const [state, setState] = useState({}); 
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [amountUsd, setAmountUsd] = useState(1000.00);
  const achRate = 0.00279;
  const fixedFee = 2.00
  const [achFee, setAchFee] = useState(2.79);
  const [totalFee, setTotalFee] = useState(4.79);
  const [convertAmountUsd, setconvertAmountUsd] = useState(1000.00);
  const [receiveAmountKrw, setReceiveAmountKrw] = useState(1000.00);
  const [rate, setRate] = useState(1);

  const rates = async () => {
    const response = await fetch('https://api.exchangerate-api.com/v4/latest/KRW');
    // console.log(response.json()['rates']['USD'])
    let local_rate = (1/(await response.json())['rates']['USD']).toFixed(2)
    setRate( await local_rate)
    calculate(await local_rate)
}
    useEffect(() => {
      rates()
      calculate(rate, amountUsd)
    }, [])

    const calculate = async (rate, value ) => {
      // console.log(value, amountUsd, fixedFee, achFee, rate)
      if (value === undefined) {
        value = amountUsd
      }
      let convertAmountUsd = value - fixedFee - achFee
      // console.log(convertAmountUsd)
      if (convertAmountUsd < 0){
        convertAmountUsd = 0
      }
      setconvertAmountUsd(convertAmountUsd)
      setReceiveAmountKrw((convertAmountUsd * rate).toFixed(2))
      // console.log(convertAmountUsd, receiveAmountKrw)

  }

    const handleSetAmountUsd = (value) => {
      // console.log("handleSetAmountUsd", value)
      setAmountUsd(value)
      // console.log("amountUsd", amountUsd, "value", value)
      setAchFee( (achRate * value).toFixed(2))
      // console.log("achFee", achFee)
      setTotalFee( (achRate * value + fixedFee).toFixed(2))
      // console.log("totalFee", totalFee)
      calculate(rate, value)
    }
 
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/        );
    };

  const handleSendMessage = async () => {
    if (!name) {
      alert("Please enter your name")
      return
    }
  
   if (!email || validateEmail(email) === false) {
      alert("Please enter a valid email address")
      return
    } 
    
    if (!message || message.length < 10 ){
      alert("Please enter a message with more than 10 characters")
      return
    }


  //  console.log(name, email, message)
    
   try {
    await addDoc(collection(db, "messages"), {
      name:  name,
      email: email,
      time: serverTimestamp(),
      message: message
    });
    alert("Message sent successfully!")
    setDisableSendButton(true)
    } catch (error) {
      console.error(
        "Unable to send message. Please try again.", error
    );
    alert("Unable to send message. Please try again.")
}
};
  
    return (
        <main>
        <div className="position-relative">
          {/* shape Hero */}
      <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-2 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                 
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        WonMove{" "}
                        <span> Fast, Secure, and Low-Cost Money Transfers from the US to South Korea.</span>
                      </h1>
                      <p className="lead text-white">
                      미국에서 한국으로의 빠르고 안전하며 저렴한 송금 서비스.
                      </p>
                      <div className="btn-wrapper">
                      {/* <Link to={'/register'}> */}
                      <a href="#promo">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                        >
                        
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Sign up 가입하기</span>
                        </Button>
                        {/* </Link> */}
                      </a>
                        {/* <Link to={'/checkout'}>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            I'm interested
                          </span>
                        </Button>
                        </Link> */}
                      </div>
                    </Col>
                  </Row>
                </div>
                </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
          
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/theme/happy_family.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <FaMoneyBillTransfer />
                    </div> */}
                    <h3>Transfer your money to friend or family</h3>
                    <p>
                   가격으로 친구나 가족에게 돈을 송금하세요
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                             <BsCurrencyExchange/>
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0">
                             Competitive Exchange Rates
                            </h5>
                            <h6>
                            경쟁력 있는 환율
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0">Transparent Low Fees</h5>
                          <h6>투명한 저렴한 </h6>
                          
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <BsFillRocketTakeoffFill />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0">
                            Secure and Fast
                            </h5>
                            <h6>
                            안전하고 빠릅니다
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("../../assets/img/theme/img-1-1200x1000.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        Ease of Use
                      </h4>
                      <h5 className="text-italic text-white">사용 편의성</h5>
                      <p className="lead text-italic text-white">
                        Receive payment at your convenience
                      </p>
                      <p className="lead text-italic text-white">
                      편리한 시간에 결제 받으세요
                      </p>
                      <ul className="list-unstyled mt-5 ">
                      <li className="text-white">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="info"
                            >
                             <BsBank/>
                            </Badge>
                          </div>
                          <div>
                            <h5 className="text-white">
                             Via Bank Account 
                            </h5>
                            <h6 className="text-white">
                            은행 계좌를 통해
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="info"
                            >
                              <BsChatFill />
                            </Badge>
                          </div>
                          <div>
                            <h5></h5>
                            <h5 className="text-white">Via Kakao Pay</h5>
                          <h6 className="text-white">카카오페이를 통해 </h6>
                          <img src="https://t1.kakaocdn.net/kakaocorp/kakaocorp/admin/5e604f55017800001.png" alt="" width="24" height="24"/>

                          </div>
                        </div>
                      </li>
                      
                    </ul>
                    
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                    <BsBank/>
                    </div> */}
                    <h3>Our fee structure</h3>
                    <div>
                    <Form>
  <FormGroup row>
    <Label
      for="example amount"
      sm={3}
    >
      You send
    </Label>
    <Col sm={7}>
      <Input
        id="amount"
        name="amount"
        value={amountUsd}
        onChange={(e) => handleSetAmountUsd(e.target.value)}
        type="number"
      />
        {/* <img class="wds-flag wds-flag-usd" src="https://wise.com/web-art/assets/flags/usd.svg" alt="" width="24" height="24"/> */}
    </Col>
    <Col sm={2}>
     
        <img class="wds-flag wds-flag-usd" src="https://wise.com/web-art/assets/flags/usd.svg" alt="" width="24" height="24"/>
    USD
    </Col>
  </FormGroup>
  <FormGroup row>
    <Label
      for="exampleSelect"
      sm={4}
    >
      ACH Fee
    </Label>
    <Col sm={8}>
    <Label
      for="exampleSelect"
      sm={10}
    >
      {achFee} USD
    </Label>
    </Col>
  </FormGroup>
  <FormGroup row>
    <Label
      for="exampleSelect"
      sm={4}
    >
      Our Fee
    </Label>
    <Col sm={8}>
    <Label
      for="exampleSelect"
      sm={5}
    >
      {fixedFee.toLocaleString(undefined, {maximumFractionDigits:2})} USD
    </Label>
    </Col>
  </FormGroup>
  <FormGroup row>
    <Label
      for="exampleSelect"
      sm={4}
    >
      Total Fees
    </Label>
    <Col sm={8}>
    <Label
      for="exampleSelect"
      sm={5}
    >
      {totalFee} USD
    </Label>
    </Col>
  </FormGroup>
  <hr />
  <FormGroup row>
    <Label
      for="exampleSelect"
      sm={4}
    >
      Total Amount to convert
    </Label>
    <Col sm={6}>
    <Label
      for="exampleSelect"
      sm={8}
    >
      {nf.format(convertAmountUsd)} USD
    </Label>
    </Col>
  </FormGroup>
  <FormGroup row>
    <Label
      for="exampleSelect"
      sm={4}
    >
      Exchange Rate
    </Label>
    <Col sm={8}>
    <Label
      for="exampleSelect"
      sm={5}
    >
      {nf.format(rate)} WON/USD
    </Label>
    </Col>
  </FormGroup>
  <FormGroup row>
    <Label
      for="exampleSelect"
      sm={4}
    >
      Recipient Receives
    </Label>
    <Col sm={6}>
    <Label
      for="exampleSelect"
      sm={5}
    >
      {nf.format(receiveAmountKrw)} WON
    </Label>
    </Col>
    <Col sm={2}>
     
     <img class="wds-flag wds-flag-usd" src="https://wise.com/web-art/assets/flags/krw.svg" alt="" width="24" height="24"/>
 KRW
 </Col>
  </FormGroup>
</Form>
                    </div>
                  
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section className="section pb-0 bg-gradient-warning">
            
              {/* <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("../../assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Modern Interface</h4>
                      <p className="text-white">
                        The Arctic Ocean freezes every winter and much of the
                        sea-ice then thaws every summer, and that process will
                        continue whatever.
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Awesome Support
                          </h5>
                          <p>
                            The Arctic Ocean freezes every winter and much of
                            the sea-ice then thaws every summer, and that
                            process will continue whatever.
                          </p>
                          <a
                            className="text-success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            Modular Components
                          </h5>
                          <p>
                            The Arctic Ocean freezes every winter and much of
                            the sea-ice then thaws every summer, and that
                            process will continue whatever.
                          </p>
                          <a
                            className="text-warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> 
          </section>

          <section className="section section-lg">
          <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Meet the team</h2>
                  <p className="lead text-muted">
                  Committed to You, Committed to Excellence
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/jamesc.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">James C</span>
                        <small className="h6 text-muted">Engineering</small>
                        {/* <p>I am absolutely blown away by WonMove! 🚀 It’s like having a personal coding guru right at your fingertips. The way it breaks down complex problems is just phenomenal. Highly recommend to anyone serious about nailing their coding interviews!</p> */}
                      </h5>
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/richardh.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Richard H</span>
                        <small className="h6 text-muted">
                          Business Development
                        </small>
                        {/* <p>I was a bit skeptical at first, thinking, how much can a tool really help? But after using WonMove for just a week, I saw a significant improvement in my problem-solving skills. It's a game-changer for anyone in tech.</p> */}
                      </h5>
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-3">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/misol2.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-3 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Misol C</span>
                        <small className="h6 text-muted">Operations</small>
                        {/* <p>Effective. Efficient. Essential.</p> */}
                      </h5>
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Col>
                {/* <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../../assets/img/theme/team-4-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Jane D</span>
                        <small className="h6 text-muted">Software Engineer at a Hedge Fund</small>
                        <p>Thanks to WonMove, I landed a job at my dream company! The interview prep was intense, but this tool made it so much more manageable. I can’t thank the team enough for creating something so helpful.</p>
                      </h5> */}
                      {/* <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div> */}
                    {/* </div>
                  </div>
                </Col> */}
              </Row>
              </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  {/* <h2 className="display-3 text-white">Build something</h2> */}
                  <p className="lead text-white">
                    {/* According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record low maximum sea ice extent tihs year down
                    to low ice. */}
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                {/* <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Building tools</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Grow your market</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Launch time</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col> */}
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Leave feedback or suggestion without signing up</h4>
                      <p className="mt-0">
                        With WonMove, transferring your money has never been easier.
                      </p>
                      <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          {/* <InputGroupAddon addonType="prepend"> */}
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          {/* </InputGroupAddon> */}
                          <Input
                            placeholder="Your name"
                            type="text"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                            onFocus={(e) =>
                              setState({ nameFocused: true })
                            }
                            onBlur={(e) =>
                              setState({ nameFocused: false })
                            }
                            
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          {/* <InputGroupAddon addonType="prepend"> */}
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          {/* </InputGroupAddon> */}
                          <Input
                            placeholder="Email address"
                            type="email"
                            value={email}
                            required={true}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={(e) =>
                              setState({ emailFocused: true })
                            }
                            onBlur={(e) =>
                              setState({ emailFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          value={message}
                          required={true}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          onClick={handleSendMessage}
                          disabled={disableSendButton}
                        >
                          Send Message
                        </Button>
                      </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
          <a id="promo"></a>
          <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Special Early Bird Offer{" "}
                      </h3>
                      <h4 className="text-white">
                      특별 얼리버드 혜택{" "}
                      </h4>
                      <p className="lead text-white mt-3">
                        Join our waitlist now and pay zero fees on your first transfer up to $1000!
                      </p>
                      <p className="lead text-white mt-3">
                      지금 대기자 명단에 가입하고 첫 번째 $1000 이하의 송금 수수료가 면제됩니다!
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                    <Link to={'/register'}>
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        size="lg"
                      >
                      
                        Sign up now
                      </Button></Link>
                    </Col>
                  </Row>
                  </div>
              </Card>
              </Container>
          </section> 
          <section className="section section-lg bg-gradient-default">
              {/* <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Build something</h2>
                  <p className="lead text-white">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record low maximum sea ice extent tihs year down
                    to low ice.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Building tools</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Grow your market</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Launch time</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
              </Row> */}
            
            {/* SVG separator */}
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          </section>
        </div>
        
          

        </main>
        
    )
}

export default LandingPage;